import React, { Component } from "react";
import Director from "./Director";
import Hero from "./Hero";
import CourseCarousel from "./CourseCarousel";
import Footer from "./Footer";
import About from "./About";
import "./Carousel.module.css";
import Navbar from "./Navbar";
import Facility from "./Facility";

export default class HomePage extends Component {
  constructor(props) {
    super(props);
    this.refDir = React.createRef();
    this.refFac = React.createRef();
    this.refContact = React.createRef();
    this.refAbout = React.createRef();
    this.refCourse = React.createRef();
  }
  render() {
    return (
      <div>
        <Navbar
          refAbout={this.refAbout}
          refDir={this.refDir}
          refFac={this.refFac}
          refContact={this.refContact}
          refCourse={this.refCourse}          
        />
        <Hero />
        <About refAbout={this.refAbout} />
        <CourseCarousel refCourse={this.refCourse} />
        <Director refDir={this.refDir} />
        <Facility refFac={this.refFac} />
        <Footer refContact={this.refContact} />
      </div>
    );
  }
}
