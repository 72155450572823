import React, { Component } from "react";
import "./App.css";
// import "animate.css/animate.min.css";
// import AllEvents from "./componets/AllEvents";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import EventDetails from "./componets/EventDetails";
import HomePage from "./componets/HomePage";
import About from "./componets/About";
import StudRegis from "./componets/StudRegis";

class App extends Component {
  render() {
    return (
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Routes>
          <Route path="/" element={<HomePage  />} />
          {/* <Route path="/events" element={<AllEvents />} /> */}
          <Route exact path="/about" element={<About more={true}/>} />
          <Route exact path='/regis' element={<StudRegis more={true}/>} />
          {/* <Route path="/:name/" element={<EventDetails />} /> */}
        </Routes>
      </BrowserRouter>
    );
  }
}

export default App;
