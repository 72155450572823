import React, { Component } from "react";
import Toppers from "../assests/Toppers.png";

export default class Hero extends Component {
  constructor(props) {
    super();
    this.width = window.innerWidth;
  }

  render() {
    return (
      <>
        <div className="h-screen bg-black/5 vid">
          <section>
            <div className="flex flex-col md:flex-row">
              <div className="py-1 text-[#5375e2] opacity-90">
                <div className="container flex flex-col items-center evr md:flex-row md:my-24">
                  <div className="lg:m-10 m-4 flex flex-col  h-[85vh] pb-20 md:pb-0 justify-center flex-shrink w-full mbox lg:w-5/6">
                    <svg viewBox="0 0 1320 300" className="h-auto md:mx-0">
                      {this.width < 640 ? (
                        <text x="50%" y="50%" dy=".35em" textAnchor="middle">
                          SCOM
                        </text>
                      ) : (
                        <text x="16%" y="65%" dy=".35em" textAnchor="middle">
                          SCOM
                        </text>
                      )}
                    </svg>

                    <h2 className="mb-2 text-3xl leading-relaxed md:text-2xl sm:text-xl text-center md:text-left w-full md:leading-snug text1">
                      Computer Centre
                    </h2>
                    <p className="mb-4 text-lg md:text-base text-[#7791a1] text2 text-center md:text-left">
                      Learn basics Without Tension
                    </p>
                    <div className="flex md:justify-start justify-center w-full">
                      <a
                        href="https://bit.ly/scomstudentreg"
                        rel="noreferrer"
                        target={"_blank"}
                        className="px-4 py-2 bg-transparent border rounded shadow text-[#f65868] border-[#f65868] hover:bg-[#f65868] hover:text-white hover:shadow-lg hover:border-transparent"
                      >
                        Register Now
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <img src={Toppers} alt="" className="w-[90%] h-[90%] md:w-[60%] md:h-[60%] md:mt-[18vh] mx-auto md:mx-0 md:ml-[6vw]" />
            </div>
          </section>
        </div>
      </>
    );
  }
}
