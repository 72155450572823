import React, { Component } from "react";
import { FloatingWhatsApp } from 'react-floating-whatsapp'

class Footer extends Component {
  state = {};

  render() {
    return (
      <>
        <div ref={this.props.refContact} id="contact" className=" divider text-[2vh]:divider-vertical"></div>
        <div className="flex justify-center">
          <div className="flex flex-col w-[75vw] md:flex-row m-5   ">
            <iframe
              title="Ashwamedha 22"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3929.012424600036!2d76.29961491479438!3d10.01583189284029!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x7b65b39b8aa790e0!2zMTDCsDAwJzU3LjAiTiA3NsKwMTgnMDYuNSJF!5e0!3m2!1sen!2sin!4v1674902780754!5m2!1sen!2sin"
              className="flex flex-grow bg-base-300 place-items-center"
              height={300}
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
            <div className=" divider md:divider-horizontal"></div>

            <div
              className="flex flex-col justify-center w-full md:w-80 bg-base-300 place-items-center"
              width={300}
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            >
              <div className="p-10 text-[#5375e2] font-bold">
                <div className="col-md-12 col-sm-12 col-xs-12 ">
                  <label className="text-[3vh]">
                    <b>Address</b>
                  </label>
                  <div className="col-md-12 col-sm-12 col-xs-12 ">
                    <label className="text-[2vh]">
                      Muzhikal Building, 2nd Floor, Near Changampuzha Park,Edappally - 682024,  kochi ,Kerala,India
                    </label>
                  </div>
                  <div className="col-md-12 col-sm-12 col-xs-12 mt-5">
                    <label className="text-[2vh]">
                      Contact No: +91 9995919120
                    </label>
                    <br />
                    <label className="text-[2vh]">
                      Mail ID: scomcoaching@gmail.com
                    </label>
                  </div>
                  <div className="flex flex-row justify-center mt-5 text-white">
                    <a href="tel:+919995919120" className="pr-5"><span className="material-symbols-outlined">call</span></a>
                    <a href="mailto:scomcoaching@gmail.com?subject='Enquiry'&body='Just popped in to say hello'"><span className="material-symbols-outlined">mail</span></a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className=" divider text-[2vh]:divider-vertical"></div>
        </div>
        <footer className="p-10 rounded footer footer-center text-base-content">
          <div>
            
          </div>

          <div className="grid grid-flow-col gap-4">
            <a href={process.env.PUBLIC_URL + "/#"} className="link link-hover text-[#f65868]">
              Back to Top
            </a>
          </div>
        </footer>
        <FloatingWhatsApp
          accountName="SCOM"
          phoneNumber="+919995919120"
          message="Hello, I'd like to know more about your products"
          position="right"
          color="green"
          statusMessage="Active"
          darkMode
          allowClickAway
          allowEsc
        />
      </>
    );
  }
}

export default Footer;
