import React, { Component, useState } from "react";
import { useTransition, animated } from "@react-spring/web";
import handleViewport from "react-in-viewport";
import Library from "../assests/library.jpg";
import ClassRoom1 from "../assests/classroom1.jpg";
import ClassRoom2 from "../assests/classroom2.jpg";
import ComputerLab from "../assests/computerlab.jpg"


class FacilityBlock extends Component {

  constructor(props) {
    super();
    this.width = window.innerWidth
  }

	render() {
		return (
			<div id="facilities">
				<section  className="p-4 lg:p-8 dark:text-gray-100">
						<h1 id="facilities" className="text-3xl font-semibold text-center text-gray-800 capitalize lg:text-4xl dark:text-white">
							Facilities
						</h1>
						<div className="flex justify-center mx-auto mt-6 mb-10">
							<span className="inline-block w-40 h-1 rounded-full bg-[#f65868]" />
							<span className="inline-block w-3 h-1 mx-1 rounded-full bg-[#f65868]" />
							<span className="inline-block w-1 h-1 rounded-full bg-[#f65868]" />
						</div>
						<div className="container flex mb-10 justify-center">
							<div className="flex flex-col md:flex-row">
								<img src={ComputerLab} alt="H3" className="h-80 dark:bg-gray-500 " />
							</div>
							{this.width>640?<div className="flex flex-col ml-10 md:flex-row-reverse">
								<img src={ClassRoom1} alt="H2" className="h-80 dark:bg-gray-500" />
							</div>:""}
							</div>
						<div className="container flex justify-center">
							<div className="flex flex-col lg:flex-row">
								<img src={ClassRoom2} alt="H1" className="h-80 dark:bg-gray-500" />
							</div>
							{this.width>640?<div className="flex flex-col ml-10 lg:flex-row-reverse">
								<img src={Library} alt="H2" className="h-80 dark:bg-gray-500" />
							</div>:""}
						</div>
				</section>
			</div>
		);
	}
}
const AboutBlock = (props) => {
  const { inViewport, forwardedRef } = props;
  const [isVisible, setVisible] = useState(false);
  const transition = useTransition(isVisible, {
    from: { x: -300, opacity: 0 },
    enter: { x: 0, opacity: 1 },
    delay: 300,
    leave: { x: -300, opacity: 0 },
    config: { duration: 300 },
  });
  if (inViewport && !isVisible) {
    setVisible(true);
  } 

  return (
    <div ref={forwardedRef}>
      {transition((style, item) =>
        item ? (
          <animated.div
            className="w-full "
            style={style}
          >
            <FacilityBlock />
          </animated.div>
        ) : (
          <div className=""></div>
        )
      )}
    </div>
  );
};
const ViewportBlock = handleViewport(AboutBlock /* options: {}, config: {} */);

const Facility = (props) => {
  return (
    <div ref={props.refFac} className="min-h-[50vh]" id="facilities">
      <ViewportBlock />
    </div>
  );
};

export default Facility;
