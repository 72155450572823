import React, { useState } from "react";
import { useTransition, animated } from "@react-spring/web";
import aboutImg from "../assests/about_us.jpeg";
import { handleViewport } from "react-in-viewport";
import { useParams } from "react-router-dom";
import { Component } from "react";

function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}

const AboutBlock = (props) => {
  const { inViewport, forwardedRef } = props;
  const [isVisible, setVisible] = useState(false);
  const transition = useTransition(isVisible, {
    from: { x: -300, opacity: 0 },
    enter: { x: 0, opacity: 1 },
    delay: 300,
    config: { duration: 300 },
  });
  if (inViewport && !isVisible) {
    setVisible(true);
  }

  return (
    <div ref={forwardedRef} id="about">
      {transition((style, item) =>
        item ? (
          <animated.div className="w-full overflow-hidden " style={style}>
            <AboutContent />
          </animated.div>
        ) : (
          <div className="h-[85vh]"></div>
        )
      )}
    </div>
  );
};

const ViewportBlock = handleViewport(AboutBlock /* options: {}, config: {} */);
const About = (props) => {

  if (!props.more) {
    return (
      <div className="mt-12 md:mt5" ref={props.refAbout}>
        <ViewportBlock />
      </div>
    );
  } else {
    return (
      <div ref={props.refAbout}>
        <AboutContent more={true} />
      </div>
    );
  }
};

export default withParams(About);
class AboutContent extends Component {
  render() {
    return (
      <div className="flex flex-col justify-center h-full overflow-x-hidden md:mt-0 mt-28">
        <div className="justify-center flex-auto p-4 lg:-mx-6 lg:flex lg:items-center sm:flex sm:items-center">
          <div
            className={
              this.props.more
                ? "flex flex-col justify-center w-full h-full rounded-lg lg:w-1/4 sm:w-1/2 lg:mx-6 lg:h-full sm:h-full "
                : "flex flex-col justify-center w-full h-full rounded-lg lg:w-1/4 sm:w-1/2 lg:mx-6 lg:h-full sm:h-full"
            }
          >
            <div
              className={
                this.props.more
                  ? "object-cover w-full object-center rounded-lg md:fixed md:left-5 md:w-[350px] top-0 md:m-5 bottom-0 flex flex-col justify-center"
                  : ""
              }
            >
              <img
                className={
                  this.props.more
                    ? "object-cover object-center rounded-lg "
                    : "object-cover object-center rounded-lg "
                }
                src={aboutImg}
                alt=""
              />
            </div>
          </div>
          <div id="about" className="lg:w-1/2 lg:px-6 lg:mt-0 sm:w-1/2 sm:ml-6">
            <p className="mt-2 text-3xl font-semibold text-justify text-[#f65868]">
              <span className="inline-block w-16 h-0.5 bg-[#f65868]  mb-2 mr-12" />
              ABOUT US
            </p>
            <div className="max-w-[50 rem] mt-6 text-lg text-justify text-white">
              <p>
                SCOM is a cutting-edge platform created to cater to the educational needs of students in computer education. The platform functions as a centralized hub where all parties in the computer education ecosystem can come together to receive training, learn, and collaborate. As it is online, students and parents have the flexibility to access the platform at any time and from anywhere. They can also reach us via phone if they have any questions or need assistance.
              </p>
              <p>
              &emsp;SCOM's creation is the result of 24 years of experience in the field of education. The founder, starting as a teacher, has since then honed their skills and knowledge and eventually established the SCOM institution in March 2011. This platform represents a culmination of the founder's journey and provides a comprehensive solution for all players in the computer education ecosystem.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
