import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import compImg from "../assests/csimg.jpeg";
import funImg from "../assests/ipimg.jpg";
import workImg from "../assests/pyimg.jpg";

const useTilt = () => {
  const ref = useRef(null);

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const state = {
      rect: undefined,
      mouseX: undefined,
      mouseY: undefined,
    };

    let el = ref.current;

    const handleMouseMove = (e) => {
      e.preventDefault();

      if (!el) {
        return;
      }
      if (!state.rect) {
        state.rect = el.getBoundingClientRect();
      }
      if (e.touches) {
        state.mouseX = e.touches[0].clientX;
        state.mouseY = e.touches[0].clientY;
      } else {
        state.mouseX = e.clientX;
        state.mouseY = e.clientY;
      }

      const px = (state.mouseX - state.rect.left) / state.rect.width;
      const py = (state.mouseY - state.rect.top) / state.rect.height;

      el.style.setProperty("--px", px);
      el.style.setProperty("--py", py);
    };

    const handleMouseLeave = () => {
      el.style.setProperty("--px", 0.5);
      el.style.setProperty("--py", 0.5);
    };

    el.addEventListener("mousemove", handleMouseMove);
    el.addEventListener("mouseleave", handleMouseLeave);
    el.addEventListener("touchmove", handleMouseMove);
    el.addEventListener("touchend", handleMouseLeave);

    return () => {
      el.removeEventListener("mousemove", handleMouseMove);
      el.removeEventListener("mouseleave", handleMouseLeave);
      el.removeEventListener("touchmove", handleMouseMove);
      el.removeEventListener("touchend", handleMouseLeave);
    };
  }, []);

  return ref;
};

const Slide = ({
  image,
  title,
  subtitle,
  description,
  offset,
  isPageBackground,
}) => {
  const active = offset === 0 ? true : null;

  const ref = useTilt(active);

  return (
    <div
      id="courses"
      ref={ref}
      className="w-[90vw] slide lg:w-[50vw]"
      data-active={active}
      style={{
        "--offset": offset,
        "--dir": offset === 0 ? 0 : offset > 0 ? 1 : -1,
      }}
    >
      <div className="bg-black w- opacity-40"></div>
      <a
        href="https://bit.ly/scomstudentreg"
        target={"_blank"}
        rel={"noreferrer"}
        className="w-[90vw] slideContent md:w-[60%] bg-opacity-40"
        style={{
          backgroundImage: `url('${image}')`,
        }}
      >
        <div className="slideContentInner">
          {title && (
            <h2
              className="text-5xl slideTitle lg:text-6xl text-[#f65868] lg:right-10"
              dir="auto"
            >
              {title}
            </h2>
          )}
          {subtitle && (
            <h3
              className="text-5xl slideSubtitle lg:text-6xl before:text-[#f65868] lg:left-24"
              dir="auto"
            >
              {subtitle}
            </h3>
          )}
          {description && (
            <p className="text-xl slideDescription lg:text-2xl" dir="auto">
              {description}
            </p>
          )}
        </div>
      </a>
    </div>
  );
};

Slide.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  offset: PropTypes.number.isRequired,
  isPageBackground: PropTypes.bool,
};

const Carousel = ({ slides, isPageBackground }) => {
  const [slideIndex, setSlideIndex] = useState(0);

  const handlePrevSlide = () => {
    setSlideIndex((prev) => (prev === 0 ? slides.length - 1 : prev - 1));
  };

  const handleNextSlide = () => {
    setSlideIndex((prev) => (prev + 1) % slides.length);
  };
  return (
    <section className="slidesWrapper " id="courses">
      <div className="slides ">
        <button
          className="prevSlideBtn z-30 top-2/3 md:top-1/2 -left-[-1%] md:-left-[5%]"
          onClick={handleNextSlide}
        >
          <i className="fas fa-chevron-left" />
        </button>
        <a
          href="https://bit.ly/scomstudentreg"
          target={"_blank"}
          rel={"noreferrer"}
          className="opacity-0 md:hidden z-20 bg-white top-0 left-0 right-0 bottom-0 cursor-pointer"
          style={{
            position: "absolute",
          }}
        >
          ssssss
        </a>
        {[...slides, ...slides, ...slides].map((slide, i) => {
          let offset = slides.length + (slideIndex - i);

          if (typeof slide === "string") {
            return (
              <Slide
                image={slide}
                offset={offset}
                isPageBackground={isPageBackground}
                key={i}
              />
            );
          } else {
            return (
              <Slide
                image={slide.image}
                title={slide.title}
                subtitle={slide.subtitle}
                description={slide.description}
                offset={offset}
                isPageBackground={isPageBackground}
                key={i}
              />
            );
          }
        })}
        <button
          className="nextSlideBtn z-30 top-2/3 md:top-1/2 -right-[-1%] md:-right-[5%] "
          onClick={handlePrevSlide}
        >
          <i className="fas fa-chevron-right" />
        </button>
      </div>
    </section>
  );
};

Carousel.propTypes = {
  slides: PropTypes.array.isRequired,
  isPageBackground: PropTypes.bool,
};

const slides = [
  {
    id: 1,
    title: "Computer Science",
    subtitle: "XI & XII",
    description: "For CBSE & State Syllabus",
    image: compImg,
  },
  {
    id: 6,
    title: "Advanced",
    subtitle: "Excel",
    description: "",
    image: workImg,
  },
  {
    id: 5,
    title: "MS Office",
    subtitle: "",
    description: "Typing, Word, Excel, Powepoint",
    image: workImg,
  },
  {
    id: 4,
    title: "Programming in",
    subtitle: "C/C++",
    description: "For Engineering Students & All Level of Students",
    image: workImg,
  },
  {
    id: 3,
    title: "Python",
    subtitle: "",
    description: "",
    image: workImg,
  },
  {
    id: 2,
    title: "Informatic Practices",
    subtitle: "XI & XII",
    description: "For CBSE & State Syllabus",
    image: funImg,
  },
];

const CourseCarousel = (props) => (
  <div id="courses" ref={props.refCourse}>
    <h1 className="text-[#f65868] text-5xl text-center mt-10">Courses</h1>
    <div className="flex justify-center mx-auto mt-6">
      <span className="inline-block w-40 h-1 rounded-full bg-[#f65868]" />
      <span className="inline-block w-3 h-1 mx-1 rounded-full bg-[#f65868]" />
      <span className="inline-block w-1 h-1 rounded-full bg-[#f65868]" />
    </div>
    <div className="flex justify-center mt-5">
      <a href="https://bit.ly/scomstudentreg" target={"_blank"} rel={"noreferrer"}>
        <button className="bg-transparent hover:bg-[#f65868] text-[#f65868] font-semibold hover:text-white py-2 px-4 border border-[#f65868] hover:border-transparent rounded">
          Apply Now
        </button>
      </a>
    </div>
    <Carousel slides={slides} isPageBackground />
    <div>
      <h1 className="text-[#f65868] text-5xl text-center">Online <span className="text-white">Coaching</span></h1>
      <div className="flex justify-center mx-auto mt-6">
        <span className="inline-block w-40 h-1 rounded-full bg-[#f65868]" />
        <span className="inline-block w-3 h-1 mx-1 rounded-full bg-[#f65868]" />
        <span className="inline-block w-1 h-1 rounded-full bg-[#f65868]" />
      </div>
      <div className="flex flex-row-reverse mr-10 mt-5 md:mt-0">
        <a href="https://onlinecourse.scomedu.com/" target={"_blank"} rel={"noreferrer"}>
          <button className="bg-transparent hover:bg-[#f65868] text-[#f65868] font-semibold hover:text-white py-2 px-4 border border-[#f65868] hover:border-transparent rounded">
            Log In
          </button>
        </a>
      </div>
      <p className="text-2xl m-10 text-justify text-white">
        Online classes are a convenient and flexible way to learn new skills and advance your education. Our online computer classes are designed to provide you with the knowledge and skills you need to succeed in today's digital world.
        Our online computer classes are self-paced, allowing you to learn at your own speed and on your own schedule. You can access your class materials and assignments 24/7, so you can study and complete assignments whenever it's convenient for you.
      </p>
      <a href="https://replit.com" target={"_blank"} rel={"noreferrer"}>
        <button className="ml-10 bg-transparent hover:bg-[#f65868] text-[#f65868] font-semibold hover:text-white py-2 px-4 border border-[#f65868] hover:border-transparent rounded">
          Online Compiler
        </button>
      </a>
    </div>
  </div>
);

export default CourseCarousel;
